import getSymbolFromCurrency from 'currency-symbol-map'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'
import { ExportForm } from '@/classes/ExportForm'
import { ImpactCost } from '@/classes/stats/ImpactCost.js'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import BrowserCache from '@/services/browser-cache.js'
import GeneralStatsService from '@/services/general-stats.service'
import StatsService from '@/services/stats.service'
import DateRange from '@/components/DateRange/DateRange.vue'
import ImpactCostGeneral from '@/components/Graphs/ImpactCostGeneral/ImpactCostGeneral.vue'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import UserInteractionRcs from '@/components/Stats/Rcs/UserInteractionRcs.vue'
import DeliveredRcs from '@/components/Stats/Rcs/DeliveredRcs.vue'
import UnsubscribedRcs from '@/components/Stats/Rcs/UnsubscribedRcs.vue'
import EventBus from '@/util/EventBus'
import StatsMixin from '../../Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import InteractionBar from '@/components/Stats/InteractionBar/InteractionBar.vue'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { RcsGraphicData } from '@/classes/stats/RcsGraphicData'
import { RcsCalculatedData } from '@/classes/stats/Calculations/Rcs/RcsCalculatedData'

export default {
  name: 'ChannelRcsStats',
  components: {
    HeaderTopDashboard,
    StatsTitle,
    DateRange,
    apexchart: VueApexCharts,
    ImpactCostGeneral,
    UserInteractionRcs,
    DeliveredRcs,
    UnsubscribedRcs,
    StatCard,
    RadialBar,
    RadialBarWithLegend,
    SimpleBar,
    StackedColumn,
    InteractionBar,
    StackedMultiple,
    Pie,
  },
  mixins: [StatsMixin],
  data: function () {
    return {
      debouncedInput: '',
      timeout: null,
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalCampaigns: 0,
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      additional: undefined,
      data: [],
      impactCost: new ImpactCost(),
      exportForm: new ExportForm(),
      ready: true,
      campaginDataSource: {},
      readyStats: false,
      rcsCalculatedData: new RcsCalculatedData(),
      cards: [],
      deliveredRatioGraphic: null,
      clicksRatioGraphicData: null,
      deliveredGraphicData: null,
      redGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
    }
  },
  methods: {
    setGraphicsData () {
      const rcsGraphicData = new RcsGraphicData(this.rcsCalculatedData, this)
      this.cards = rcsGraphicData.getCardsData()
      this.deliveredRatioGraphic = rcsGraphicData.getDeliveredRatioGraphicGraphicData()
      this.clicksRatioGraphicData = rcsGraphicData.getClicksRatioGraphicData()
      this.unsubscribedRatioBarChart = rcsGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = rcsGraphicData.getReBouncedRatioGraphicData()
      this.redGraphicData = rcsGraphicData.getRedGraphicData()
      this.deliveredGraphicData = rcsGraphicData.getDeliveredGraphicData()
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(5)
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      params = this.getParams()
      StatsService.getRcsChannelDatatable(params)
        .then(
          (response) => {
            this.readyStats = false
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalCampaigns = response.total
            if (this.totalCampaigns > 0) {
              this.setPieChart(this.additional[0])
            }
            setTimeout(() => {
              this.rcsCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            }, 1000)
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
      this.getimpactCostStats()
    },

    setTableData (data) {
      data.forEach(data => {
        data.undelivered_total = this.calculateUndeliveredTotal(data)
        data.pending = this.calculatePending(data)
      })
      this.content = data
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total) + parseInt(data.rejected_total) + parseInt(data.expired_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },

    setPieChart (totals) {
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.pending = this.calculatePending(totals)
      this.resetReady()
    },

    getColumns () {
      const columns = []
      this.headers.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      this.setExportData()
      const url = getEnv('VUE_APP_API_URL') + 'api/stats/rcs/channel-export-' + urlSuffix + '?' + this.exportForm.getUrlParams()
      window.location.href = url
    },

    setExportData () {
      this.exportForm.setFromParamsAndColums(this.getParams(), this.getColumns())
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    updateImpactCostChartData (data) {
      this.impactCost.series = [
        {
          name: 'rcs',
          color: '#FFB178',
          data: data.rcs,
        },
      ]
    },

    getimpactCostStats () {
      if (!this.globalControl.initDate || !this.globalControl.endDate) {
        return
      }

      GeneralStatsService.getimpactCostStats({
        initDate: this.globalControl.initDate,
        endDate: this.globalControl.endDate,
        globals: this.globalControl.globals,
        daysOrHour: this.impactCost.daysOrHour,
      }).then((data) => {
        this.updateImpactCostChartData(data)
      })
    },

    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.getDataFromApi()
    },
  },
  computed: {
    hasCampaigns () {
      return this.content.length !== 0
    },

    headers () {
      return [
        {
          text: this.$t('Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Campaña'),
          value: 'campaign_name',
        },
        {
          text: this.$t('Tipo'),
          value: 'campaign_type_name',
        },
        {
          text: this.$t('Fecha'),
          value: 'campaign_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Entregados'),
          value: 'delivered_total',
        },
        {
          text: this.$t('Entregados únicos'),
          value: 'delivered_unique',
        },
        {
          text: this.$t('Rechazados'),
          value: 'rejected_total',
        },
        {
          text: this.$t('No entregados'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Caducados'),
          value: 'expired_total',
        },
        {
          text: this.$t('Pendiente'),
          value: 'pending',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
      ]
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    'impactCost.daysOrHour': function (newVal, oldVal) {
      this.getimpactCostStats()
    },
    params: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
    searchTerm () {
      this.options.page = 1
      this.getDataFromApi()
    },
  },

  created () {
    this.globalControl.globals = false
    if (!this.$isSectionsBlockeds('costs')) {
      this.headers.push({
        text: this.$t('Coste'),
        align: 'start',
        value: 'cost',
        class: 'w-8',
      })
    }
  },
}
